.full-Main {
    width: 1250px;
    height: 1500px;
    /* background-color: yellow; */
}

.Btn_Btn {
    width: 143px;
    height: 40px;
    padding: 8px;
    border-radius: 4px;
    color: rgba(255, 255, 255, 1);
    background: rgba(90, 91, 91, 1);
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    /* margin-left: 22px; */
    margin-top: 11px;
}

.mid_main {
    width: 1250px;
    height: 600px;
    /* background-color: red; */
    margin-top: 15px;
}

.First_table {
    width: 1250px;
    height: 130px;
    margin-bottom: 10px;

    /* background-color: green; */
}

.first_heading {
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: rgba(59, 130, 246, 1);
    margin-top: 15px;


}

.first-table-dta {
     margin-top: 15px;  
}